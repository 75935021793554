import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row text-muted">
          <div className="col-6 text-start">
            <ul className="list-inline">
              <li className="list-inline-item">
                <Link to="/" className="text-muted" href="#">
                  Support
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/" className="text-muted" href="#">
                  Help Center
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/" className="text-muted" href="#">
                  Privacy
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/" className="text-muted" href="#">
                  Terms of Service
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 text-end">
            <p className="mb-0">
              &copy; 2020 -{" "}
              <Link to="/" href="index.html" className="text-muted">
                Offrz.net
              </Link>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
