import React, { useState } from 'react';

import { toast } from "react-toastify";

import  axios  from 'axios';

function ForgotPassword(){

  const [email, setEmail] = useState("");


  const forgetHandle = async(e) => {
    e.preventDefault();
  try {
    let res = await axios.post("http://portal.offrz.net/api/auth/forget_password", {email},{headers:{
      token:"mt1"
    }})
    
    
    if (res.data.status === "success") {
      toast.success("Please check your email Id we have sent a new password.")
    }else {
      toast.error(res.data.msg)
    }
    
  } catch (error) {
    
  }


  }

  
  return (
    <div className="loginpage">
    <div className="card-plain">
<form className="form" onSubmit={forgetHandle}>
<div className="header">
<div className="logo-container">
<img src="img/logo.png" alt="logo" />
</div>

</div>
<div className="content">
 
<div className="input-group input-lg">
<input type="text" className="form-control" onChange={(e)=> setEmail(e.target.value)} placeholder="Enter Email" />
<span className="input-group-addon">
<i className="far fa-envelope"></i>
</span>
</div>
 
</div>
<div className="footer text-center">
<input type="submit" className="sendbtn" value="Submit" /> 
<p><a href="#" className="link"> Need Help? </a></p>
</div>
</form>
</div>
</div>
  )
}

export default ForgotPassword