import React, { Component, useEffect, useState } from "react";
import Layout from "../../components/layout/layout";
import Bredcrumb from "../../components/layout/bredcrumb";
import { Route, useParams } from "react-router-dom";

import AuthUser from "../../components/AuthUser";
import { Watch } from "react-loader-spinner";
import { toast } from "react-toastify";
import Iframe from "react-iframe";
import Select from "react-select";



const Edithotleads = () => {
  let { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const { http, setToken, token } = AuthUser();
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [data, setData] = useState([]);
  const [images, setImages] = useState([]);
  const [address, setAddress] =useState("");
  const [selectData, setSelectData] = useState({
    id: "",
    image: "",
  });

  const [selectBuySetHoo, setSelectBuySet] = useState([]);

  const [buyerList, setBuyerList] = useState([]);
  const [buyerSel, setBuyerSel] = useState([]);
  const [customForm, setCustomForm] = useState({
    des: "",
    lats: "",
    longs: "",
    min_price: "",
    max_price: "",
  });
  const [files, setFiles] = useState(null);

  const customFormHandle = (e) => {
    setCustomForm({ ...customForm, [e.target.name]: e.target.value });
  };

  const fileSelectedHandler = async (e) => {
    setLoader(true);
    let f = e.target.files[0];
    var formData = new FormData();
    formData.append("id", selectData.id);
    formData.append("image", f);

    try {
      const res = await http.post(
        "http://portal.offrz.net/api/add_images_lead",
        formData
      );
      console.log(res.data);
      setSelectData({ image: res.data.data });
      toast.success("Successfully removed");
      setLoader(false);
    } catch (error) {}
  };


  const handleChangeBuyer = (selectedOption) => {
  
    setBuyerSel(selectedOption)
    
  };


  const filesSelectedHandler = async (e) => {
    setLoader(true);
    let f = e.target.files;
    var formData = new FormData();
    formData.append("id", selectData.id);
    formData.append("counts", f.length);
    let c = 0;

    for (const key of Object.keys(f)) {
      formData.append("images" + c, f[key]);
      c++;
    }

    try {
      const res = await http.post(
        "http://portal.offrz.net/api/add_images_lead",
        formData
      );
      setImages(res.data.data);
      // console.log(res.data);
      toast.success("Successfully removed");
      setLoader(false);
    } catch (error) {}
  };

  const deleteHandle = async (index) => {
    setLoader(true);
    try {
      const res = await http.post(
        "http://portal.offrz.net/api/delete_lead_image",
        {
          indexs: index,
          id: selectData.id,
        }
      );
      setImages(res.data.data);
      setLoader(false);
      toast.success("Successfully removed");
    } catch (error) {
      toast.error(error.response);
    }
  };

  const handleInput = (e) => {
    setData((pre) =>
      pre.filter(function myFunction(num) {
        return num.name === e.target.name ? (num.value = e.target.value) : pre;
      })
    );
  };

  useEffect(() => {
    setLoader(true);
    const fetchData = async () => {
      const res = await http.get("/get_lead_detail/"+id);

      let ar = [];
      let arr = [];
      let datacus = res.data.data;

      setAddress(`${datacus.address}, ${datacus.city}, ${datacus.state}`)


      setCustomForm({
        ...customForm,
        des: datacus.description,
        min_price: datacus.min_price,
        max_price: datacus.max_price,
        lats: datacus.lats,
        longs: datacus.longs,
      });
      let buyer = [];
      let selectBuySet = [];
      for (let j = 0; j < res.data.buyers.length; j++) {
        buyer.push({ value:res.data.buyers[j].id, label: res.data.buyers[j].first_name+' '+res.data.buyers[j].last_name })
        
        if( datacus.agents.includes( res.data.buyers[j].id )){
          selectBuySet.push({value: res.data.buyers[j].id, label:res.data.buyers[j].first_name+' '+res.data.buyers[j].last_name})
         }
      }  
      setBuyerSel(selectBuySet)
      // setSelectBuySet(selectBuySet)
      setBuyerList(buyer)
      setImages(JSON.parse(res.data.data.all_images));
      setSelectData({ id: res.data.data.id, image: res.data.data.image });
      for (let index = 0; index < res.data.data.data.length; index++) {
        let n = res.data.data.data[index].name;
        if (
          n == "Property Address" ||
          n == "Property City" ||
          n == "Property State" ||
          n == "Property County" ||
          n == "Bedrooms" ||
          n == "Bathrooms" ||
          n == "Property SQFT" ||
          n == "Lot Size SQFT"
        ) {
          ar.push(res.data.data.data[index]);
        } else {
          arr.push(res.data.data.data[index]);
        }
      }

      setData([...ar, ...arr]);
      //   console.log();
      setLoader(false);
    };
    fetchData().catch(console.error);
  }, [message]);
  useEffect(() => {
    // setCurrentData(buyerList.slice(offset, offset + pageLimit));
  }, [offset, currentData]);

  const submitHandle = async (e) => {
    e.preventDefault();
    
    let agents = [];
    for (let j = 0; j < buyerSel.length; j++) {
      agents.push(buyerSel[j].value)
      
    }
    setLoader(true);
    try {
      const res = await http.post(
        "http://portal.offrz.net/api/update_lead_tags",
        {
          data,
          ...customForm,
          id: selectData.id,
          agents
        }
      );
      console.log(res.data);
      setLoader(false);
      toast.success("Successfully removed");
    } catch (error) {
      toast.error(error.response);
    }
  };

  return (
    <Layout>
      <div className="container-fluid p-0">
        <section className="contentinnerpage edithotleads listingpage">
          {loader && <Watch ariaLabel="loading-indicator" />}
          
          <Bredcrumb title="Edit Hot Leads" desc="Welcome to Offrz" />
          <div className="row clearfix">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3>Basic Information</h3>

                  <p>Description text here...</p>
                </div>
                <div className="card-body">
                  <form className="addpropertform" onSubmit={submitHandle}>
                    <div className="row clearfix">
                      <div className="col-md-5">
                        <div className="mainimg">
                          <img
                            width=""
                            height=""
                            src={"http://portal.offrz.net/" + selectData.image}
                            alt=""
                          />
                        </div>
                        <div className="mainimgupload">
                          <div className="form-group">
                            <input type="file" onChange={fileSelectedHandler} />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="imagegallry">
                          <div className="form-group">
                            <div className="image-gallary-line">
                              <div className="row">
                                {images &&
                                  images.map((img, i) => (
                                    <div className="col-sm-2">
                                      <div className="imagebox">
                                        <img
                                          src={"http://portal.offrz.net/" + img}
                                        />
                                        <div className="btnboximg">
                                          <button
                                            onClick={() => deleteHandle(i)}
                                            className="img-del"
                                          >
                                            <i class="fas fa-trash-alt"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                            <input
                              type="file"
                              multiple
                              onChange={filesSelectedHandler}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        {" "}
                        <hr />
                      </div>
                      <div className="col-12">
                        <div className="mapsection">
                          <Iframe
                            src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyA28VgphVkwvtSU0fxgsysah-gKBQZi8L4&q="+address}
                            width="100%"
                            height="450"
                            style="border:0;"
                            allowfullscreen=""
                            loading="lazy"
                          ></Iframe>
                        </div>
                      </div>
                      <div className="col-12">
                     
                        <hr />
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="">Buyer List</label>
                          <Select isMulti
                          value={buyerSel}
                          onChange={handleChangeBuyer}
                          options={buyerList && buyerList} />
                        </div>
                      </div>

                      {data.map((item, index) => {
                        return (
                          <div className="col-sm-3">
                            <div className="form-group">
                              <label htmlFor="">{item.name}</label>
                              <input
                                type="text"
                                onChange={handleInput}
                                className="form-control"
                                // placeholder={item.name}
                                value={item.value}
                                name={item.name}
                              />
                            </div>
                          </div>
                        );
                      })}

                      <div className="col-sm-12">
                        <div className="form-group">
                          <div className="form-line">
                            <textarea
                              onChange={customFormHandle}
                              rows="4"
                              value={customForm.des}
                              name="des"
                              className="form-control no-resize"
                              placeholder="Property Description"
                            ></textarea>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <div className="form-line">
                                <label htmlFor="">Lats</label>
                                <input
                                  type="text"
                                  onChange={customFormHandle}
                                  className="form-control"
                                  value={customForm.lats}
                                  name="lats"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <div className="form-line">
                                <label htmlFor="">Longs</label>

                                <input
                                  type="text"
                                  onChange={customFormHandle}
                                  className="form-control"
                                  value={customForm.longs}
                                  name="longs"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <div className="form-line">
                                <label htmlFor="">Min price</label>
                                <input
                                  type="text"
                                  onChange={customFormHandle}
                                  className="form-control"
                                  value={customForm.min_price}
                                  name="min_price"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <div className="form-line">
                                <label htmlFor="">Max price</label>

                                <input
                                  type="text"
                                  onChange={customFormHandle}
                                  className="form-control"
                                  value={customForm.max_price}
                                  name="max_price"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="formbtnsec">
                      <button className="themebtn" type="submit">
                        {" "}
                        Update
                      </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Edithotleads;
