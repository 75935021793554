import React from "react";

export default function Bredcrumb({ title, desc }) {
  return (
    <div className="block-headers">
      <div className="row">
        <div className="col-lg-5 col-md-5 col-sm-12">
          <h2>
            {title}
            <small>{desc}</small>
          </h2>
        </div>
        <div className="col-lg-7 col-md-7 col-sm-12 text-md-right">
          <div className="breadrubmbtn">
            <ul className="breadcrumb float-md-right">
              <li className="breadcrumb-item">
                <a href="index.html">
                  <i className="align-middle " data-feather="home"></i> Offrz
                </a>
              </li>
              <li className="breadcrumb-item active">Property</li>
            </ul>
            {/* <button
              className="btn btn-white btn-icon btn-round hidden-sm-down float-right ml-3"
              type="button"
            >
               <i class="fas fa-plus"></i> 
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
