import React from 'react'
import Bredcrumb from "../components/layout/bredcrumb";
import Layout from "../components/layout/layout";
import AuthUser from "./../components/AuthUser";

export default function Contract() {
    const {token,user, http, updateUser} = AuthUser();

    const getFreshList = async () =>  {
      try {
      let res = await http.get("http://portal.offrz.net/api/get_buyer_detail/"+user.id);
      updateUser(res.data.data);
      } catch (error) {
        console.log(error);
      }
    }

  return (
    <Layout>
    <div className="container-fluid p-0">
      <section className="contentinnerpage listingpage">
        <Bredcrumb title="Contract" desc="Welcome to Offrz" />

        <div className="row clearfix">
          <div className="col-12">
            <div className="card">
              <div className="card-body">       
              <div className='row'>
                  <div className='col-md-3'></div>
                  <div className='col-md-6'> <div className="notification">
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam laboriosam soluta corporis sit, ipsum incidunt molestias qui repellendus! Deleniti esse ut repudiandae modi. Doloribus inventore voluptatem.</p>
              <a target="_blank" className='btn btn-blue' href={user.signature_url} >
                  Contract Sign With OFFRZ  
                    
                </a>
                  </div></div>
                  <div className='col-md-3'></div>
                  </div>           
             
               {user.signature_status == 2 &&  <button onClick={getFreshList} className='btn btn-blue' >
                 Your document is under review please wait
                    
                </button>}


                {user.signature_status == 3 &&  <button onClick={getFreshList}  className='btn btn-blue' >
                 Your document is rejected by team, please submit again
                    
                </button>}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  
  </Layout>
  )
}
