import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout/layout'
import Bredcrumb from "../../components/layout/bredcrumb"; 
import { Link } from "react-router-dom";
import { Table } from 'react-bootstrap';
import Paginator from 'react-hooks-paginator';
import AuthUser from "../../components/AuthUser";
import { Watch } from  'react-loader-spinner'

export default function BuyerList() {
  const {http,setToken, token} = AuthUser();
  const [buyerList, setBuyerList] = useState([]);
  const [message, setMessage] = useState("")
  const pageLimit = 10;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true)
    const fetchData = async () => {
      const res = await http.get('/get_all_buyer?page='+currentPage);    
      setBuyerList(res.data.data.data);    
      setLoader(false)  
    }
    fetchData().catch(console.error);;
  },[message])
  useEffect(() => {
    setCurrentData(buyerList.slice(offset, offset + pageLimit));
  }, [offset, currentData]);


  const statusHandle = async (id, status) => {
    setLoader(true)
    try {
      const res = await http.post('/change_buyer_status', {id, status});

      setMessage(res.data.msg)
      setLoader(false)
    } catch (error) {
      setLoader(false)
    }
  } 

  const signatureHandle = async (id, signature_status) => {
    setLoader(true)
    try {
      const res = await http.post('/signature_status_update', {id, signature_status});

      setMessage(res.data.msg)
      setLoader(false)
    } catch (error) {
      setLoader(false)
    }
  } 


 const  allButton = (item)  => {

      if (item.signature_status == 2) {
      return ( <div> <button onClick={()=>signatureHandle(item.id, "4")}>Approvel</button>
      <button onClick={()=>signatureHandle(item.id, "3")}>Reject</button>
      <a target="_blank" href={item.signature_url}>View</a></div>)
     
  }

  if (item.signature_status == 4) {
   
    return ( <div>  <a target="_blank" href={item.signature_url}>View</a></div>)
   
}
}
const setCurrentPageUpdate =() => {
  console.log('test');
  setCurrentPage(currentPage+1)
}

    return (
    <Layout>
       <div className="container-fluid p-0">
       {loader && <Watch
    height="100"
    width="100"
    color='grey'
    ariaLabel='loading'
  />}
        <section className="contentinnerpage listingpage">
          <Bredcrumb title="Buyer List" desc="Welcome to Offrz" />

          <div className="row clearfix">
								<div className="col-12">
									<div className="card">
										<div className="body">
										<div className="hotlisttable">
										<div className="responsive">
                      {message}
                    
	<Table striped bordered hover>
  
  <thead>
    <tr>      
      <th>First Name</th>
      <th>Last Name</th>
      <th>Mobile Number</th>
      <th>Email Address</th>
      <th>Company</th>
      <th>Website</th>
      <th>Action</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    
    {buyerList && buyerList.map((item, index) => (
      <tr> 
        <td>{item.first_name}</td>
        <td>{item.last_name}</td>
        <td>{item.contact_no}</td>
        <td>{item.email}</td>
        <td> -</td>
        <td> -</td>
        <td>{allButton(item)}</td>
        <td><Link to={"/buyer-profile/"+item.id} className='btn btnview'>View</Link> 

        {item.status == 0? <a href="#" className='btn approve' onClick={()=>statusHandle(item.id,1)}>Approve</a>: <a href="#" className='btn reject' onClick={()=>statusHandle(item.id,0)}>Reject</a>} 
      </td>
    </tr>
    ))}
  </tbody>
</Table>
<Paginator
        totalRecords={buyerList.length}
        pageLimit={4}
        pageNeighbours={1}
        setOffset={setOffset}
        currentPage={currentPage}
        setCurrentPage={()=>setCurrentPageUpdate()}
      />
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</section>
      </div>
    </Layout>
    )
  }