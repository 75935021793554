import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout/layout'
import Bredcrumb from "../../components/layout/bredcrumb"; 
import { Link } from "react-router-dom";
import { Table } from 'react-bootstrap';
import Paginator from 'react-hooks-paginator';

import AuthUser from "../../components/AuthUser";


export default function HotList() {
  const {http,setToken, token, user} = AuthUser();

  const [hostList, sethostList] = useState([]);
  const [message, setMessage] = useState("")
  const pageLimit = 10;

  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);


  useEffect(() => {

    const fetchData = async () => {
      const res = await http.get('/get_all_leads');
    
      sethostList(res.data.data.data);
      
    }
    fetchData().catch(console.error);;
  },[message])

  useEffect(() => {
    setCurrentData(hostList.slice(offset, offset + pageLimit));
  }, [offset, currentData]);


 
 

    return (
    <Layout>
       <div className="container-fluid p-0">
        <section className="contentinnerpage listingpage">
          <Bredcrumb title="host List" desc="Welcome to Offrz" />

          <div className="row clearfix">
								<div className="col-12">
									<div className="card">
										<div className="body">
										<div className="hotlisttable">
										<div className="responsive">
                      {message}
	<Table striped bordered hover>
  <thead>
    <tr>
      
    <th>Property Address </th>
    <th>city</th>
    <th>State</th>
    <th>Zip</th>
     
	 
	  <th>Bedrooms</th>
    <th>Bathrooms</th>
    <th>Sq Footage</th>
      
      

	  <th>Action</th>

    </tr>
  </thead>
  <tbody>
    
    {hostList && hostList.map((item, index) => (
      <tr> 
        <td>{item.address}</td>
        <td>{item.city}</td>
      <td>{item.state}</td>
      <td></td>
	
      <td>{item.bedroom}</td>
      <td>{item.bathroom}</td>
      <td>{item.squre_fit}</td>
      
  
	
      <td><Link to={user.role == "buyer"? "/hotleads-detail/"+item.id:"/edit-hotleads/"+item.id} className='btn btnview'>View Property Details</Link> <a href="#" className='btn approve'>Approve</a>
 
      
	 
	 
	  </td>
    </tr>
    ))}
    
	
	

  </tbody>
</Table>
<Paginator
        totalRecords={hostList.length}
        pageLimit={4}
        pageNeighbours={1}
        setOffset={setOffset}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
</div>
									</div>
											</div>
									</div>
								</div>
								
						
						 
						</div>
      </section>
      </div>
    </Layout>
    )
  }
