import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {

  
    Redirect,
    useHistory,
    useLocation
  } from "react-router-dom";
export default function AuthUser(){
    let history = useHistory();

    const getToken = () =>{
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    }

    const getUser = () =>{
        const userString = sessionStorage.getItem('user');
        const user_detail = JSON.parse(userString);
        return user_detail;
    }



    const [token,setToken] = useState(getToken());
    const [user,setUser] = useState(getUser());

    const saveToken = (user,token) =>{
        sessionStorage.setItem('token',JSON.stringify(token));
        sessionStorage.setItem('user',JSON.stringify(user));
       
        setToken(token);
        setUser(user);
        history.push('/home');
    }

    const updateUser = (user) =>{
        setUser(user);
        sessionStorage.setItem('user',JSON.stringify(user));
       
    
        console.log('woorkkr');
    }
    const logout = () => {
        sessionStorage.clear();
        history.push('/login');
    }

    const http = axios.create({
        baseURL:"http://portal.offrz.net/api",
        headers:{
            "Content-type" : "application/json",
            "auth" : token,
            "token":"mt1"
        }
    });
    return {
        updateUser,
        setToken:saveToken,
        token,
        user,
        getToken,
        http,
        logout
    }
}