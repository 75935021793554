import React, {  useState, useEffect } from "react";
import AuthUser from "../../components/AuthUser";
import {

  
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import { toast } from 'react-toastify';

export default function Login() {
  let history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const {http,setToken, token, user} = AuthUser();



  useEffect(()=> {
    if (token) {
      if(user.role === "admin"){
        history.push('/dashboard');
      }else {
        history.push('/hot-list');
        
      }
     
    }
  })


  const loginHandle = async (e) => {
    e.preventDefault();
   


      try {
        let res = await http.post(
        "/auth/signin",
        {  email, password  }
      
      )
      if (res.data.status === 'success') {
        toast.success("Successfully login")
        setToken(res.data.data,res.data.token);
        if(res.data.data.role === "admin"){
          history.push('/dashboard');
        }else {
          history.push('/contract-sign');
          
        }
      }else {
        toast.error(res.data.msg)
      }

      } catch (error) {
       
        toast.error(error.error)
      }
     
  };

  return (
    <div className="loginpage">
      <div className="card-plain">
        <form className="form" autoComplete="true" onSubmit={loginHandle}>
          <div className="header">
            <div className="logo-container">
              <img src="img/logo.png" alt="logo" />
            </div>
          </div>
          <div className="content">
            <div className="input-group input-lg">
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                autocomplete="on"
                className="form-control"
                placeholder="Enter Email Address"
              />
              <span className="input-group-addon">
                <i className="fas fa-user-circle"></i>
              </span>
            </div>
            <div className="input-group input-lg">
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Password"
                className="form-control"
              />
              <span className="input-group-addon">
                <i className="fas fa-lock"></i>
              </span>
            </div>
          </div>
          {/* <div className="footer text-center"> */}
          <button type="submit" className="sendbtn">
            Login
          </button>
          <p>
            <a href="#" className="link">
              Forgot Password?
            </a>
          </p>
          {/* </div> */}
        </form>
      </div>
    </div>
  );
}
