import React, { useEffect, useState } from 'react'
import Layout from '../components/layout/layout'
import Bredcrumb from "../components/layout/bredcrumb"; 
import { Link } from "react-router-dom";
import { Table } from 'react-bootstrap';
import Paginator from 'react-hooks-paginator';
import AuthUser from "../components/AuthUser";


export default function Dashboard() {
  const {http,setToken, token} = AuthUser();
  const [buyerList, setBuyerList] = useState([]);
  const [message, setMessage] = useState("")
  const pageLimit = 10;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await http.get('/get_all_buyer');    
      setBuyerList(res.data.data.data);      
    }
    fetchData().catch(console.error);;
  },[message])
  useEffect(() => {
    setCurrentData(buyerList.slice(offset, offset + pageLimit));
  }, [offset, currentData]);


  const statusHandle = async (id, status) => {
    const res = await http.post('/change_buyer_status', {id, status});
    setMessage(res.data.msg)
  } 

    return (
    <Layout>
       <div className="container-fluid p-0">
        <section className="contentinnerpage buyer-detail listingpage">       
 
			 
					<div className="row mb-2 mb-xl-3 block-headers">
						<div className="col-auto d-none d-sm-block">
							<h3 className="text-white">Dashboard</h3>
						</div>

						<div className="col-auto ms-auto text-end mt-n1">
							<span className="dropdown me-2">
      <button className="btn btn-light bg-white shadow-sm dropdown-toggle" id="day" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i class="far fa-calendar-alt"></i> Today
      </button>
      <div className="dropdown-menu" aria-labelledby="day">
        <h6 className="dropdown-header">Settings</h6>
        <a className="dropdown-item" href="#">Action</a>
        <a className="dropdown-item" href="#">Another action</a>
        <a className="dropdown-item" href="#">Something else here</a>
        <div className="dropdown-divider"></div>
        <a className="dropdown-item" href="#">Separated link</a>
      </div>
    </span>
    
							<button className="btn btn-white btn-icon btn-round hidden-sm-down float-right ml-3">
              <i class="far fa-filter"> </i>
    </button>
							<button className="btn btn-white btn-icon btn-round hidden-sm-down float-right ml-3">
              <i class="far fa-sync"> </i>
    </button>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-sm-6 col-xxl d-flex">
							<div className="card illustration flex-fill">
								<div className="card-body p-0 d-flex flex-fill">
									<div className="row g-0 w-100">
										<div className="col-6">
											<div className="illustration-text p-3 m-1">
												<h4 className="illustration-text">Welcome Back, Chris!</h4>
												<p className="mb-0">Offrz Dashboard</p>
											</div>
										</div>
										<div className="col-6 align-self-end text-end">
											<img src="img/illustrations/customer-support.png" alt="Customer Support" className="img-fluid illustration-img" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-12 col-sm-6 col-xxl d-flex">
							<div className="card flex-fill">
								<div className="card-body py-4">
									<div className="d-flex align-items-start">
										<div className="flex-grow-1">
											<h3 className="mb-2">$ 24.300</h3>
											<p className="mb-2">Total Earnings</p>
											<div className="mb-0">
												<span className="badge badge-soft-success me-2"> <i class="far fa-dollar-sign"></i> +5.35% </span>
												<span className="text-muted">Since last week</span>
											</div>
										</div>
										<div className="d-inline-block ms-3">
											<div className="stat">
                      <i class="far fa-dollar-sign align-middle text-success"></i> 
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-12 col-sm-6 col-xxl d-flex">
							<div className="card flex-fill">
								<div className="card-body py-4">
									<div className="d-flex align-items-start">
										<div className="flex-grow-1">
											<h3 className="mb-2">43</h3>
											<p className="mb-2">Pending Orders</p>
											<div className="mb-0">
												<span className="badge badge-soft-danger me-2"> <i class="far fa-shopping-bag"></i> -4.25% </span>
												<span className="text-muted">Since last week</span>
											</div>
										</div>
										<div className="d-inline-block ms-3">
											<div className="stat">
                      <i class="far fa-shopping-bag align-middle text-danger"></i> 
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-12 col-sm-6 col-xxl d-flex">
							<div className="card flex-fill">
								<div className="card-body py-4">
									<div className="d-flex align-items-start">
										<div className="flex-grow-1">
											<h3 className="mb-2">$ 18.700</h3>
											<p className="mb-2">Total Revenue</p>
											<div className="mb-0">
												<span className="badge badge-soft-success me-2"> <i class="far fa-dollar-sign"></i> +8.65% </span>
												<span className="text-muted">Since last week</span>
											</div>
										</div>
										<div className="d-inline-block ms-3">
											<div className="stat">
											<i class="far fa-dollar-sign align-middle text-info"></i>	 
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-12 col-lg-8 d-flex">
            <div className="card flex-fill">
						<div className="card-header">
							<h5 className="card-title mb-0">Latest Property</h5>
						</div>
            <div className="responsive">
                      {message}
	<Table striped bordered hover>
  <thead>
    <tr>      
      <th>First Name</th>
      <th>Last Name</th>
      <th>Mobile Number</th>
      <th>Email Address</th>
      <th>Company</th>
      <th>Website</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    
    {buyerList && buyerList.map((item, index) => (
      <tr> 
        <td>{item.first_name}</td>
        <td>{item.last_name}</td>
        <td>{item.contact_no}</td>
        <td>{item.email}</td>
        <td> -</td>
        <td> -</td>
        <td><Link to="/buyer-profile" className='btn btnview'>View</Link> 
        {item.status == 0? <a href="#" className='btn approve' onClick={()=>statusHandle(item.id,1)}>Approve</a>: <a href="#" className='btn reject' onClick={()=>statusHandle(item.id,0)}>Reject</a>} 
      </td>
    </tr>
    ))}
  </tbody>
</Table>
<Paginator
        totalRecords={buyerList.length}
        pageLimit={4}
        pageNeighbours={1}
        setOffset={setOffset}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
        </div>
					</div>
						</div>
						<div className="col-12 col-lg-4 d-flex">
							<div className="card flex-fill w-100">
								<div className="card-header">
									<span className="badge bg-info float-end">Today</span>
									<h5 className="card-title mb-0">Daily feed</h5>
								</div>
								<div className="card-body">
									<div className="d-flex align-items-start">
										<img src="img/avatars/avatar-5.jpg" width="36" height="36" className="rounded-circle me-2" alt="Ashley Briggs" /> 
										<div className="flex-grow-1">
											<small className="float-end">5m ago</small>
											<strong>Ashley Briggs</strong> started following <strong>Stacie Hall</strong><br />
											<small className="text-muted">Today 7:51 pm</small><br />

										</div>
									</div>

									<hr />
									<div className="d-flex align-items-start">
										<img src="img/avatars/avatar.jpg" width="36" height="36" className="rounded-circle me-2" alt="Chris Wood" />
										<div className="flex-grow-1">
											<small className="float-end">30m ago</small>
											<strong>Chris Wood</strong> posted something on <strong>Stacie Hall</strong>'s timeline<br />
											<small className="text-muted">Today 7:21 pm</small>

											<div className="border text-sm text-muted p-2 mt-1">
												Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing...
											</div>
										</div>
									</div>

									<hr />
									<div className="d-flex align-items-start">
										<img src="img/avatars/avatar-4.jpg" width="36" height="36" className="rounded-circle me-2" alt="Stacie Hall" />
										<div className="flex-grow-1">
											<small className="float-end">1h ago</small>
											<strong>Stacie Hall</strong> posted a new blog<br />

											<small className="text-muted">Today 6:35 pm</small>
										</div>
									</div>

									<hr />
									<div className="d-grid">
										<a href="#" className="btn btn-primary">Load more</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					
				
			 

                </section>
      </div>
    </Layout>
  )
} 