import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axios from "axios";

import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from "react-papaparse";
import Layout from "../components/layout/layout";
import Bredcrumb from "../components/layout/bredcrumb";
const animatedComponents = makeAnimated();
const baseURLFields = "http://portal.offrz.net/api/getInputFields";
const GREY = "#CCC";
const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = "#686868";

export default function Home() {
  const [showCsv, setShowCsv] = useState(false);

  const handleCsvClose = () => setShowCsv(false);
  const handleCsvShow = () => setShowCsv(true);

  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );

  const [inputfields, setInputfields] = useState([]);
  const [name, setName] = useState("");
  const [label, setLabel] = useState("");
  const [dataType, setDataType] = useState("");

  const [show, setShow] = useState(false);
  const [out, setOut] = useState([]);
  const [groupList, setGroupList] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [inputJson, setInputJson] = useState([]);
  const [inputJsonAll, setInputJsonAll] = useState([]);

  const [showInput, setShowInput] = useState(false);

  const handleInputClose = () => setShowInput(false);
  const handleInputShow = () => setShowInput(true);

  const [showGroup, setShowGroup] = useState(false);
  const handleGroupClose = () => setShowInput(false);
  const handleGroupShow = () => setShowInput(true);

  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const handleShowCreateGroupClose = () => setShowCreateGroup(false);
  const handleShowCreateGroupShow = () => setShowCreateGroup(true);

  const [assignData, setAssignData] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [assignGroup, setAssignGroup] = useState([]);

  const [mutliMain, setMultiMain] = useState([]);
  const [mutliSub, setMultiSub] = useState([]);

  const [inputGroupName, setInputGroupName] = useState("");
  const [inputGroupType, setInputGroupType] = useState("");
  const [groupIdMain, setGroupIdMain] = useState([]);
  const [groupIdSub, setGroupIdSub] = useState([]);

  const handleChangeMain = (o) => {
    let g = [];
    for (let i = 0; i < o.length; i++) {
      g.push(o[i].value);
    }
    setGroupIdMain(g);
    console.log(groupIdMain);
  };

  const handleChangeSub = (o) => {
    let g = [];
    for (let i = 0; i < o.length; i++) {
      g.push(o[i].value);
    }

    setGroupIdSub(g);
  };

  const onchangeMethod = (e) => {
    let spl = e.target.value.split(",");
    console.log(spl);
    assignData[spl[1]] = inputfields[spl[0]];
    setAssignData((assignData) => [...assignData]);

    console.log(assignData);

    const inputfieldsData = [...inputfields];
    inputfieldsData[spl[0]].status = 0;
    setInputfields(inputfieldsData);
  };

  function sendAllData() {
    console.log("herror");
    // console.log(inputJsonAll);
    axios
      .post(
        "http://portal.offrz.net/api/insert_address_tags",
        {
          assign: assignData,
          csv: inputJsonAll,
          group: [...groupIdMain, ...groupIdSub],
        },
        {
          headers: {
            token: "mt1",
            auth: "z1OcPfxhX0o3cup08SYGeUwZWcoAtyMJY1p0xjktHEZXNLJRSIBw5hd2evC3RdMA9l6aZwT4cRCYaYx0",
          },
        }
      )
      .then((response) => {
        console.log("hehhehehheh");

        console.log(response.data);
        toast.success("Wow so easy!");
      })
      .catch((err) => {
        toast.error(err);
        console.log("hello brother" + err.error);
      });
  }

  function createPost() {
    axios
      .post(
        "http://portal.offrz.net/api/storeInputField",

        {
          name: name,
          data_parm: label,
          type: dataType,
        },
        {
          headers: {
            token: "mt1",
            auth: "z1OcPfxhX0o3cup08SYGeUwZWcoAtyMJY1p0xjktHEZXNLJRSIBw5hd2evC3RdMA9l6aZwT4cRCYaYx0",
          },
        }
      )
      .then((response) => {
        setInputfields([...inputfields, response.data.data]);

        toast.success("Successfully Added");
        setLabel("");
        setDataType("");
        setName("");
      })
      .catch((err) => {
        console.log("hello brother" + err);
      });
  }

  function createGroup() {
    axios
      .post(
        "http://portal.offrz.net/api/storegroup",

        {
          gname: inputGroupName,
          type: inputGroupType,
        },
        {
          headers: {
            token: "mt1",
            auth: "z1OcPfxhX0o3cup08SYGeUwZWcoAtyMJY1p0xjktHEZXNLJRSIBw5hd2evC3RdMA9l6aZwT4cRCYaYx0",
          },
        }
      )
      .then((response) => {
        if (response.data.data.type == "cat") {
          toast.success("Successfully Created Group");
          setMultiMain([
            ...mutliMain,
            {
              value: response.data.data.id,
              label: response.data.data.name,
            },
          ]);

          console.log(mutliMain);
        } else {
          setMultiSub([
            ...mutliSub,
            {
              value: response.data.data.id,
              label: response.data.data.name,
            },
          ]);
        }

        setInputGroupName("");
        setInputGroupType("");
      })
      .catch((err) => {
        toast.error("Error " + err);
      });
  }

  return (
    <Layout>
      <div className="container-fluid p-0">
        <section className="contentinnerpage listingpage">
          <Bredcrumb title="Property List" desc="Welcome to Offrz" />

          <div className="row clearfix">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className={styles.container}>
                   <div className="importbtns"> 
                    <Button variant="secondary" onClick={handleCsvShow}>
                    <i className="fas fa-cloud-upload-alt"></i>  Add data
                    </Button>
                    </div>

                    {showCsv && (
                      <Modal
                        show={showCsv}
                        onHide={handleCsvClose}
                        backdrop="static"
                        keyboard={false}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Modal title</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                          <p>Csv file.</p>

                          <CSVReader
                            onUploadAccepted={async (results) => {
                              let res = await axios.get(baseURLFields, {
                                headers: {
                                  token: "mt1",
                                  auth: "z1OcPfxhX0o3cup08SYGeUwZWcoAtyMJY1p0xjktHEZXNLJRSIBw5hd2evC3RdMA9l6aZwT4cRCYaYx0",
                                },
                              });

                              setAssignData([
                                ...Array(results.data[0].length).keys(),
                              ]);

                              setInputfields(res.data.data);
                              setGroupList(res.data.group_data);

                              let multiMainBox = [];
                              for (
                                let i = 0;
                                i < res.data.group_data.length;
                                i++
                              ) {
                                multiMainBox.push({
                                  value: res.data.group_data[i].id,
                                  label: res.data.group_data[i].name,
                                });
                              }
                              setMultiMain(multiMainBox);
                              let multiSubBox = [];
                              for (
                                let i = 0;
                                i < res.data.sub_group_data.length;
                                i++
                              ) {
                                multiSubBox.push({
                                  value: res.data.sub_group_data[i].id,
                                  label: res.data.sub_group_data[i].name,
                                });
                              }
                              setMultiSub(multiSubBox);

                              setOut(results.data);
                              setInputJson(results.data[0]);
                              setInputJsonAll(results.data);

                              setShowCsv(false);
                              setShow(true);

                              setZoneHover(false);
                            }}
                            onDragOver={(event) => {
                              event.preventDefault();
                              setZoneHover(true);
                            }}
                            onDragLeave={(event) => {
                              event.preventDefault();
                              setZoneHover(false);
                            }}
                          >
                            {({
                              getRootProps,
                              acceptedFile,
                              ProgressBar,
                              getRemoveFileProps,
                              Remove,
                            }) => (
                              <>
                                <div
                                  {...getRootProps()}
                                  style={Object.assign(
                                    {},
                                    styles.zone,
                                    zoneHover && styles.zoneHover
                                  )}
                                >
                                  {acceptedFile ? (
                                    <>
                                      <div style={styles.file}>
                                        <div style={styles.info}>
                                          <span style={styles.size}>
                                            {formatFileSize(acceptedFile.size)}
                                          </span>
                                          <span style={styles.name}>
                                            {acceptedFile.name}
                                          </span>
                                        </div>
                                        <div style={styles.progressBar}>
                                          <ProgressBar />
                                        </div>
                                        <div
                                          {...getRemoveFileProps()}
                                          style={styles.remove}
                                          onMouseOver={(event) => {
                                            event.preventDefault();
                                            setRemoveHoverColor(
                                              REMOVE_HOVER_COLOR_LIGHT
                                            );
                                          }}
                                          onMouseOut={(event) => {
                                            event.preventDefault();
                                            setRemoveHoverColor(
                                              DEFAULT_REMOVE_HOVER_COLOR
                                            );
                                          }}
                                        >
                                          <Remove color={removeHoverColor} />
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    "Drop CSV file here or click to upload"
                                  )}
                                </div>
                              </>
                            )}
                          </CSVReader>
                        </Modal.Body>
                      </Modal>
                    )}

                    <Modal
                      dialogClassName="modal-xl"
                      show={show}
                      onHide={handleClose}
                      aria-labelledby="example-custom-modal-styling-title"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Data</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row csutominfo">
                          <div className="col-md-4">
                            <ul>
                              {out[0] &&
                                out[0].map((x, i) => (
                                  <li key={i + "w2222"}>{x}</li>
                                ))}
                            </ul>
                          </div>
                          <div className="col-md-4">
                            <ul>
                              {out[1] &&
                                out[1].map((x, i) => (
                                  <li key={"kkk" + i}>{x}</li>
                                ))}
                            </ul>
                          </div>
                          <div className="col-md-4">
                            {inputJson.map((x, i) => {
                              return (
                                <select
                                  onChange={(e) => onchangeMethod(e)}
                                  className="form-control mb-2"
                                  key={i + "33333"}
                                >
                                  <option>Select</option>
                                  {inputfields &&
                                    inputfields.map((xx, ii) => {
                                      return (
                                        <option
                                          value={ii + "," + i}
                                          disabled={
                                            xx.status == "1" ? false : true
                                          }
                                          key={"kkk" + ii}
                                        >
                                          {xx.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              );
                            })}
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                        <Button variant="primary" onClick={handleInputShow}>
                          Create field
                        </Button>
                        <Button
                          variant="primary"
                          onClick={() => {
                            handleClose();
                            setShowGroup(true);
                          }}
                        >
                          Next
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    {showInput && (
                      <Modal
                        show={showInput}
                        onHide={handleInputClose}
                        backdrop="static"
                        keyboard={false}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Modal title</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                          <form>
                            <div className="form-group">
                              <label htmlFor="">Name</label>
                              <input
                                className="form-control"
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="">Label</label>
                              <input
                                className="form-control"
                                name="label"
                                value={label}
                                onChange={(e) => setLabel(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="">Name</label>
                              <input
                                className="form-control"
                                type="text"
                                name="dataType"
                                value={dataType}
                                onChange={(e) => setDataType(e.target.value)}
                              />
                            </div>
                          </form>
                        </Modal.Body>

                        <Modal.Footer>
                          <Button variant="secondary">Close</Button>
                          <Button
                            variant="primary"
                            onClick={() => createPost()}
                          >
                            Save changes
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    )}

                    {showGroup && (
                      <Modal
                        show={showGroup}
                        onHide={handleGroupClose}
                        backdrop="static"
                        keyboard={false}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Create Group</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                          <form>
                            <div className="form-group">
                              <p>{assignGroup}</p>

                              <div>
                                <label htmlFor="">Main Group </label>
                                <Select
                                  onChange={handleChangeMain}
                                  closeMenuOnSelect={false}
                                  components={animatedComponents}
                                  // defaultValue={[colourOptions[4], colourOptions[5]]}
                                  isMulti
                                  options={mutliMain}
                                />
                              </div>

                              <div>
                                <label htmlFor="">Sub Group</label>
                                <Select
                                  onChange={handleChangeSub}
                                  closeMenuOnSelect={false}
                                  components={animatedComponents}
                                  // defaultValue={[colourOptions[4], colourOptions[5]]}
                                  isMulti
                                  options={mutliSub}
                                />
                              </div>
                            </div>
                          </form>
                        </Modal.Body>

                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={handleShowCreateGroupShow}
                          >
                            Create Group
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() => sendAllData()}
                          >
                            send data{" "}
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    )}

                    {showCreateGroup && (
                      <Modal
                        show={showCreateGroup}
                        onHide={handleShowCreateGroupClose}
                        backdrop="static"
                        keyboard={false}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Create Group</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                          <form>
                            <div className="form-group">
                              <label htmlFor="">
                                Name {inputGroupName}, {inputGroupType}
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="inputGroupName"
                                value={inputGroupName}
                                onChange={(e) =>
                                  setInputGroupName(e.target.value)
                                }
                              />
                            </div>

                            <div className="form-group">
                              <label htmlFor="">Type</label>
                              <select
                                name=""
                                value={inputGroupType}
                                className="form-control"
                                onChange={(e) =>
                                  setInputGroupType(e.target.value)
                                }
                              >
                                <option value="">Select Group</option>
                                <option value="cat">Main Group</option>
                                <option value="sub">Sub Group</option>
                              </select>
                            </div>
                          </form>
                        </Modal.Body>

                        <Modal.Footer>
                          <Button variant="secondary">Close</Button>
                          <Button
                            variant="primary"
                            onClick={() => createGroup()}
                          >
                            Save changes
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

const styles = {
  zone: {
    alignItems: "center",
    border: `2px dashed ${GREY}`,
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: 20,
  },
  file: {
    background: "linear-gradient(to bottom, #EEE, #DDD)",
    borderRadius: 20,
    display: "flex",
    height: 120,
    width: 120,
    position: "relative",
    zIndex: 10,
    flexDirection: "column",
    justifyContent: "center",
  },
  info: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: "0.5em",
    justifyContent: "center",
    display: "flex",
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: "0.5em",
  },
  progressBar: {
    bottom: 14,
    position: "absolute",
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: GREY_DIM,
  },
  default: {
    borderColor: GREY,
  },
  remove: {
    height: 23,
    position: "absolute",
    right: 6,
    top: 6,
    width: 23,
  },
};
