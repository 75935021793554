import React, { useState } from "react";
import { Link } from "react-router-dom";
import AuthUser from "../AuthUser";
import { Dashboard } from '../../pages/Dashboard';

export default function Sidebar({ open }) {
  const {token,user} = AuthUser();

  return (
    <nav id="sidebar" className={open ? "sidebar collapsed" : "sidebar"}>
      
      <div className="sidebar-content menutabdswrap js-simplebar">
        <ul className="nav nav-tabs menutabds" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="menutab-tab"
              data-bs-toggle="tab"
              data-bs-target="#menutab"
              type="button"
              role="tab"
              aria-controls="menutab"
              aria-selected="true">
               <i class="fas fa-home"></i>  Home
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="userinfo-tab"
              data-bs-toggle="tab"
              data-bs-target="#userinfo"
              type="button"
              role="tab"
              aria-controls="userinfo"
              aria-selected="false"
            >
             <i class="align-middle me-2 far fa-fw fa-user"></i>   User
            </button>
          </li>
        </ul>
        {user.role === "admin" && 
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="menutab"
            role="tabpanel"
            aria-labelledby="menu-tab"
          >
            <ul className="sidebar-nav">
             
                 
            <li className="sidebar-item">
                <Link
                  to="/dashboard" 
                  className="sidebar-link">
                <i class="fas fa-tachometer-alt"></i>
                  <span className="align-middle">Dashboard</span>
                  
                </Link>
                

             
              </li>
            <li className="sidebar-item">
                <Link
                  to="/"
                  data-bs-target="#property"
                  data-bs-toggle="collapse"
                  className="sidebar-link">
                 <i class="fas fa-home"></i>{" "}
                  <span className="align-middle">Property</span>
                  
                </Link>
                <ul
                  id="property"
                  className="sidebar-dropdown list-unstyled collapse"
                  data-bs-parent="#sidebar">
                   <li className="sidebar-item">
                    <Link
                      to="/"
                      className="sidebar-link"
                      href="#">
                      Add Property 
                    </Link>
                  </li>
                  <li className="sidebar-item">
                    <Link
                      to="/csv"
                      className="sidebar-link"
                      href="#">
                      Property List
                    </Link>
                  </li>
                 
                </ul>
              </li>
                
              <li className="sidebar-item">
                <Link
                  to="/hot-list" 
                  className="sidebar-link">
                 <i class="fas fa-sitemap"></i> {" "}
                  <span className="align-middle">Hot leads</span>
                  
                </Link>
                

             
              </li>


              <li className="sidebar-item">
                <Link
                  to="/buyer-list"                  
                  className="sidebar-link">
                 <i class="fas fa-users"></i> {" "}
                  <span className="align-middle">Buyer </span>
                  
                </Link>
              
 
              </li>
              <li className="sidebar-item">
                <Link
                  to="/"
                  data-bs-target="#buyerportal"
                  data-bs-toggle="collapse"
                  className="sidebar-link">
                   <i class="fas fa-hand-holding-usd"></i> {" "}
                  <span className="align-middle">Buyer portal</span>
                  
                </Link>
                <ul
                  id="buyerportal"
                  className="sidebar-dropdown list-unstyled collapse"
                  data-bs-parent="#sidebar">
                   <li className="sidebar-item">
                    <Link
                      to="/buyer"
                      className="sidebar-link"
                      href="#">
                     Buyer List
                    </Link>
                  </li>
               
                 
                </ul>
              </li>

            
              
            
                       </ul>
          </div>
          <div
            className="tab-pane fade"
            id="userinfo"
            role="tabpanel"
            aria-labelledby="userinfo-tab"
          >
         
            <ul className="sidebar-nav">
              <li>
                <div className="user-info m-b-20 p-b-15">
                  <div className="image">
                    <Link
                      to="/"
                      href="profile.html"
                      className=" waves-effect waves-block"
                    >
                      <img
                        src="img/avatars/avatar.jpg"
                        className="img-fluid rounded-circle "
                        alt="Chris Wood"
                      />
                    </Link>
                  </div>
                  <div className="detail">
                    <h4>{user && user.name}</h4>
                  
                  </div>
                
                
               
                </div>
              </li>

              <li>
                <div className="contactuse">
                  <small className="text-muted">Email address: </small>
                  <p>{user && user.email}</p>
                  <hr />
                  <small className="text-muted">Phone: </small>
                  <p>+ 202-555-0191</p>
                  <hr />
                
                </div>
              </li>
            </ul>
          </div>
        </div>
|| <div className="tab-content" id="myTabContent">
<div
  className="tab-pane fade show active"
  id="menutab"
  role="tabpanel"
  aria-labelledby="menu-tab"
>
  <ul className="sidebar-nav">
   
       
  <li className="sidebar-item">
      <Link
        to={user.signature_status == 4 ? "/home":""} 
        className="sidebar-link">
      <i class="fas fa-tachometer-alt"></i>
        <span className="align-middle">Dashboard</span>
        
      </Link>
      

   
    </li>
 
      
    <li className="sidebar-item">
      <Link
     to={user.signature_status == 4 ? "/hot-list":""} 
        className="sidebar-link">
       <i class="fas fa-sitemap"></i> {" "}
        <span className="align-middle">Hot leads</span>
        
      </Link>
      

   
    </li>


  

  
    
  
             </ul>
</div>

</div>}
      </div>
      
    </nav>
    );
}