import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import "./assets/css/light.css";
import "./assets/css/custom.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import Csvprint from "./pages/Csvprint";
import Signup from "./pages/Auth/Signup";
import Login from "./pages/Auth/Login";
import Forgotpassword from "./pages/Auth/Forgotpassword";
import BuyerList from "./pages/Buyer/BuyerList";
import Hotleadsdetail from "./pages/Hotleads/Hotleadsdetail";
import Edithotleads from "./pages/Hotleads/Edithotleads";
import Buyer from "./pages/Buyer/Buyer";
import Detailproperty from "./pages/Detailproperty";
import Buyerprofile from "./pages/Buyer/Buyerprofile";
import HotList from "./pages/Hotleads/HotList";
import Dashboard from "./pages/Dashboard";
// import "node_modules/react-loader-spinner/dist/loader";
import Contract from './pages/Contract';
function App() {
 

  return (
    <>
    
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        draggable={false}
        pauseOnVisibilityChange
        closeOnClick
        pauseOnHover
      />
    <Router>

      <Switch>
      <Route  exact path="/buyer-profile/:id">
      < Buyerprofile />
        </Route>
        <Route exact path="/" ><Login /></Route>


        <Route path="/home" ><Home /></Route>
        <Route path="/contract-sign" ><Contract /></Route>
         
        <Route index path="/dashboard" >
        < Dashboard />
        </Route>
        <Route index path="/csv" >
        <Csvprint />
        </Route>
        <Route index path="/login" >
        <Login />
        </Route>
        <Route index path="/signup" >
        <Signup />
        </Route>
        <Route index path="/forgotpassword" >
        <Forgotpassword />
        </Route>
        <Route index path="/buyer-list" >
        <BuyerList />
        </Route>
        <Route index path="/hotleads-detail/:id" >
        <Hotleadsdetail />
        </Route>
        <Route index path="/edit-hotleads/:id" >
        <Edithotleads />
        </Route>
        <Route index path="/buyer" >
        <Buyer />
        </Route>
        <Route index path="/property-detail" >
        < Detailproperty />
        </Route>
       
        <Route index path="/hot-list">< HotList /> </Route>
        
        
       
      </Switch>
      </Router>

    </>
  );
}

export default App;
