import React, { Component, useState } from 'react'
import Layout from '../../components/layout/layout'
import Bredcrumb from "../../components/layout/bredcrumb"; 
import { Link } from "react-router-dom";
const  Buyer  = () => {

    const [map, setMap] = useState("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26359156.301778745!2d-113.71487266929651!3d36.247498705447676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sin!4v1650874243347!5m2!1sen!2sin");

  
    return (
    <Layout>
       <div className="container-fluid p-0">
        <section className="contentinnerpage listingpage">
          <Bredcrumb title="Buyer Property List" desc="Welcome to Offrz" />

          <div className="propertylistingview">
	<div className="filter-wrap">
		<div className="fromgorup">
			 <div className="formwrap">
				 <select className="form-control" name="validation-select">
				<option value="">All</option>
				<optgroup label="CALIFORNIA">
				  <option value="Frenso">Frenso</option>
				  <option value=">Inland Empire">Inland Empire</option>
				  <option value="Los Angeles" selected>Los Angeles</option>
				  <option value="Oakland">Oakland</option>
				  <option value="San Diago">San Diago</option>
				  <option value="Sling">Sling</option>
				</optgroup>
				<optgroup label="COLORADO">
					<option value="Colorado Springs">Colorado Springs</option>
				  <option value="Denver">Denver</option>						 
				 
				</optgroup>
			  </select>
			</div>
			<div className="formwrap">
				<select className="form-control" name="validation-select">
			   <option value="">Active & Coming Soon</option>
			   <option value="">Active</option>
			   <option value="">Coming Soon</option>
			   <option value="">Pending</option>
			   <option value="">All</option>
			 </select>
		   </div>
		</div>
	</div>
	<div className="innerpropert-wrap">
		<div className="filtermapwrap">		
        <iframe src={map} width="100%" height="100%"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
		</div>
		<div className="propertytable">
			<div className="propertytableinner">
			<div className="headerlisting"><h3><strong>Showing 28</strong> properties in Los Angeles</h3></div>
			<div className="tablewrap">
				<div className="headerrow">
					<div className="colsttus"><span>Status</span></div>
					<div className="colask"><span>Asking</span></div>
					<div className="colends"><span>Ends</span></div>
					<div className="colestcoe"><span>EST COE</span></div>
					<div className="colbd"><span>BD</span></div>
					<div className="colba"><span>BA</span></div>
					<div className="colsqf"><span>SQ F</span></div>
					<div className="colbuild"><span>BUILT</span></div>
					<div className="colheart"></div>
				</div>
				<Link  to="/property-detail"> 
				<div className="propertiview activelist">
					<div className="colsttus_view"><span className="badge rounded-pill bg-success">Active</span>
					<p><span>San Pedro,</span> CA 90731</p></div>
					<div className="colask_view"><span>$297,500</span></div>
					<div className="colends_view"><span>4/26/22</span></div>
					<div className="colestcoe_view"><span>5/6/22</span></div>
					<div className="colbd_view"><span>2</span></div>
					<div className="colba_view"><span>2</span></div>
					<div className="colsqf_view"><span>971</span></div>
					<div className="colbuild_view"><span>1971</span></div>
					<div className="colheart_view"><a href="#"><span><i className="fa fa-heart" aria-hidden="true"></i></span></a></div>
				</div>
				</Link>
				<Link  to="/property-detail">
				<div className="propertiview">
					<div className="colsttus_view"><span className="badge rounded-pill bg-secondary">Coming Soon</span>
					<p><span>San Pedro,</span> CA 90731</p></div>
					<div className="colask_view"><span>---</span></div>
					<div className="colends_view"><span>---</span></div>
					<div className="colestcoe_view"><span>---</span></div>
					<div className="colbd_view"><span>2</span></div>
					<div className="colba_view"><span>2</span></div>
					<div className="colsqf_view"><span>971</span></div>
					<div className="colbuild_view"><span>1971</span></div>
					<div className="colheart_view"><a href="#"><span><i className="far fa-fw fa-heart"></i></span></a></div>
				</div></Link>
				<div className="propertiview activelist">
					<div className="colsttus_view"><span className="badge rounded-pill bg-success">Active</span>
					<p><span>San Pedro,</span> CA 90731</p></div>
					<div className="colask_view"><span>$297,500</span></div>
					<div className="colends_view"><span>4/26/22</span></div>
					<div className="colestcoe_view"><span>5/6/22</span></div>
					<div className="colbd_view"><span>2</span></div>
					<div className="colba_view"><span>2</span></div>
					<div className="colsqf_view"><span>971</span></div>
					<div className="colbuild_view"><span>1971</span></div>
					<div className="colheart_view"><a href="#"><span><i className="fa fa-heart" aria-hidden="true"></i></span></a></div>
				</div>
				<div className="propertiview">
					<div className="colsttus_view"><span className="badge rounded-pill bg-secondary">Coming Soon</span>
					<p><span>San Pedro,</span> CA 90731</p></div>
					<div className="colask_view"><span>---</span></div>
					<div className="colends_view"><span>---</span></div>
					<div className="colestcoe_view"><span>---</span></div>
					<div className="colbd_view"><span>2</span></div>
					<div className="colba_view"><span>2</span></div>
					<div className="colsqf_view"><span>971</span></div>
					<div className="colbuild_view"><span>1971</span></div>
					<div className="colheart_view"><a href="#"><span><i className="far fa-fw fa-heart"></i></span></a></div>
				</div>
				<div className="propertiview activelist">
					<div className="colsttus_view"><span className="badge rounded-pill bg-success">Active</span>
					<p><span>San Pedro,</span> CA 90731</p></div>
					<div className="colask_view"><span>$297,500</span></div>
					<div className="colends_view"><span>4/26/22</span></div>
					<div className="colestcoe_view"><span>5/6/22</span></div>
					<div className="colbd_view"><span>2</span></div>
					<div className="colba_view"><span>2</span></div>
					<div className="colsqf_view"><span>971</span></div>
					<div className="colbuild_view"><span>1971</span></div>
					<div className="colheart_view"><a href="#"><span><i className="fa fa-heart" aria-hidden="true"></i></span></a></div>
				</div>
				<div className="propertiview">
					<div className="colsttus_view"><span className="badge rounded-pill bg-secondary">Coming Soon</span>
					<p><span>San Pedro,</span>CA 90731</p></div>
					<div className="colask_view"><span>---</span></div>
					<div className="colends_view"><span>---</span></div>
					<div className="colestcoe_view"><span>---</span></div>
					<div className="colbd_view"><span>2</span></div>
					<div className="colba_view"><span>2</span></div>
					<div className="colsqf_view"><span>971</span></div>
					<div className="colbuild_view"><span>1971</span></div>
					<div className="colheart_view"><a href="#"><span><i className="far fa-fw fa-heart"></i></span></a></div>
				</div>
				<div className="propertiview activelist">
					<div className="colsttus_view"><span className="badge rounded-pill bg-success">Active</span>
					<p><span>San Pedro,</span> CA 90731</p></div>
					<div className="colask_view"><span>$297,500</span></div>
					<div className="colends_view"><span>4/26/22</span></div>
					<div className="colestcoe_view"><span>5/6/22</span></div>
					<div className="colbd_view"><span>2</span></div>
					<div className="colba_view"><span>2</span></div>
					<div className="colsqf_view"><span>971</span></div>
					<div className="colbuild_view"><span>1971</span></div>
					<div className="colheart_view"><a href="#"><span><i className="fa fa-heart" aria-hidden="true"></i></span></a></div>
				</div>
				<div className="propertiview">
					<div className="colsttus_view"><span className="badge rounded-pill bg-secondary">Coming Soon</span>
					<p><span>San Pedro,</span>  CA 90731</p></div>
					<div className="colask_view"><span>---</span></div>
					<div className="colends_view"><span>---</span></div>
					<div className="colestcoe_view"><span>---</span></div>
					<div className="colbd_view"><span>2</span></div>
					<div className="colba_view"><span>2</span></div>
					<div className="colsqf_view"><span>971</span></div>
					<div className="colbuild_view"><span>1971</span></div>
					<div className="colheart_view"><a href="#"><span><i className="far fa-fw fa-heart"></i></span></a></div>
				</div>
				<div className="propertiview activelist">
					<div className="colsttus_view"><span className="badge rounded-pill bg-success">Active</span>
					<p><span>San Pedro,</span> CA 90731</p></div>
					<div className="colask_view"><span>$297,500</span></div>
					<div className="colends_view"><span>4/26/22</span></div>
					<div className="colestcoe_view"><span>5/6/22</span></div>
					<div className="colbd_view"><span>2</span></div>
					<div className="colba_view"><span>2</span></div>
					<div className="colsqf_view"><span>971</span></div>
					<div className="colbuild_view"><span>1971</span></div>
					<div className="colheart_view"><a href="#"><span><i className="fa fa-heart" aria-hidden="true"></i></span></a></div>
				</div>
				<div className="propertiview">
					<div className="colsttus_view"><span className="badge rounded-pill bg-secondary">Coming Soon</span>
					<p><span>San Pedro,</span> CA 90731</p></div>
					<div className="colask_view"><span>---</span></div>
					<div className="colends_view"><span>---</span></div>
					<div className="colestcoe_view"><span>---</span></div>
					<div className="colbd_view"><span>2</span></div>
					<div className="colba_view"><span>2</span></div>
					<div className="colsqf_view"><span>971</span></div>
					<div className="colbuild_view"><span>1971</span></div>
					<div className="colheart_view"><a href="#"><span><i className="far fa-fw fa-heart"></i></span></a></div>
				</div>
			</div>
			<div className="endliosting">
				<h3> listings are constantly updated,<br />
					so check back soon for more deals.</h3>
			</div>
		</div>
		</div>
	</div>
</div>

                    </section>
      </div>
    </Layout>
    )
  
}

export default Buyer