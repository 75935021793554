import React, { Component } from "react";
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import Bredcrumb from "../../components/layout/bredcrumb";
import Layout from "../../components/layout/layout";
import MySelect from "../../components/mutiSelect/MySelect";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";

import axios  from 'axios';



  const allOption = {
	label: "Select all",
	value: "*"
  };

const Option = (props) => {

	return (

	  <div>
		<components.Option {...props}>
		  <input
			type="checkbox"
			checked={props.isSelected}
			onChange={() => null}
		  />{" "}
		  <label>{props.label}</label>
		</components.Option>
	  </div>
	);
  };


  const ValueContainer = ({ children, ...props }) => {
	const currentValues = props.getValue();
	let toBeRendered = children;
	if (currentValues.some(val => val.value === allOption.value)) {
	  toBeRendered = [[children[0][0]], children[1]];
	}
  
	return (
	  <components.ValueContainer {...props}>
		{toBeRendered}
	  </components.ValueContainer>
	);
  };
  
  const MultiValue = props => {
	let labelToBeDisplayed = `${props.data.label}, `;
	if (props.data.value === allOption.value) {
	  labelToBeDisplayed = "All is selected";
	}
	return (
	  <components.MultiValue {...props}>
		<span>{labelToBeDisplayed}</span>
	  </components.MultiValue>
	);
  };
  const animatedComponents = makeAnimated();

export class Buyerprofile extends Component {

	constructor(props) {
		super(props);
		this.state = {
		  optionSelectedState: null,
		  state:[],
		  city:[],
		  firstName:"",
		  lastName:"",
		  mobile:"",
		  email:"",
		  priceMinMax:"",
		  note:"",
      selectedCity:""		
		  
		};
    
	  }

	  submitHandle = async (e) => {
		e.preventDefault();
	
		let arr = [];
		for (let index = 0; index < this.state.optionSelectedState.length; index++) {
			// console.log(this.state.optionSelectedState[index].value,this.state["cityFilter"+index])
			arr.push({[this.state.optionSelectedState[index].value]:this.state["cityFilter"+index]})
			
		}
		
		try {
      const res = await axios.post('http://portal.offrz.net/api/update_buyer', {
			first_name:this.state.firstName,
			contact:this.state.mobile,
			investment: this.state.priceMinMax,
		
			service_cities:arr,
      id:this.props.match.params.id,
			last_name:this.state.lastName,
			fund_status:0,
			note:this.state.note
		}, {headers:{
			"token":"mt1",
			"auth":JSON.parse(sessionStorage.getItem("token"))
		}})

    toast.success("Successfully updated!");
    } catch (error) {
      console.log(error);
      toast.success("Something went wrong!");
    }
		// console.log(res.data);

	  }
	 
	  handleForm = (e) => {
		this.setState({[e.target.name]:e.target.value})
	  }

	  handleChangeState = (selected) => {
		 
		//  console.log(selected);
		this.setState({
			optionSelectedState: selected
		});
	  };

	  handleChangeCity = (e,num) => {
		 
		 let nm = "cityFilter"+num;
		this.setState({
			[nm]: e
		});
	  };

	  

	  async componentDidMount() {
      
		try {
			const res = await axios.get('http://portal.offrz.net/api/all_country');
      const buyerDetails = await axios.get('http://portal.offrz.net/api/get_buyer_detail/'+this.props.match.params.id,{headers:{
        "token":"mt1",
        "auth":JSON.parse(sessionStorage.getItem("token"))
      }})
      if (buyerDetails.data) {
        
        let stateName = [];
        let cityName = []
        let nm = JSON.parse(buyerDetails.data.data.service_cities);
        let c = 0;
        for (let index = 0; index < nm.length; index++) {
         
          for (const key in nm[index]) {
            console.log(nm[index][key]);
           
            // cityName.push({val:nm[index][key]})
            console.log("first" + c)
            this.setState({["cityFilter"+c]: nm[index][key]})
            stateName.push({'value':key, 'label':key,});
          
           
           }
           c = c+1;
        }
        console.log(cityName);
    
      this.setState(prevState => ({
        firstName:buyerDetails.data.data.first_name,
        lastName:buyerDetails.data.data.last_name,
        mobile:buyerDetails.data.data.contact_no,
        email:buyerDetails.data.data.email,
        priceMinMax:buyerDetails.data.data.investment,
        optionSelectedState:stateName,
        note:buyerDetails.data.data.notes.note  
			  }))

        


      }
      console.log(buyerDetails.data);
			this.setState(prevState => ({
				city: 
				  res.data.city,
				  state: 
				  res.data.state
			
			  }))

			
		} catch(e) {
			console.error(e);
		}
	}

  render() {
  

    return (
      
      <Layout>
        <div className="container-fluid p-0">
          <section className="contentinnerpage  buyerdetail listingpage">
            <Bredcrumb title="Buyer Profile" desc="Welcome to Offrz" />
            <div className="row clearfix">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Basic Information </h3>
                  </div>
                  <div className="card-body">
                    <form className="addpropertform" onSubmit={this.submitHandle}>
                      <div className="row clearfix">
                        <div className="col-sm-6">
                          <div className="form-group">
							
                            <label>First Name</label>
                            <input
							onChange={this.handleForm}
							name="firstName"
							value={this.state.firstName}
                              type="text"
                              className="form-control"
                              placeholder="First Name"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Last Name</label>
                            <input
							onChange={this.handleForm}
								name="lastName"
								value={this.state.lastName}
                              type="text"
                              className="form-control"
                              placeholder="Last Name"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Mobile NUmber</label>
                            <input
							onChange={this.handleForm}
								name="mobile"
								value={this.state.mobile}
                              type="tel"
                              className="form-control"
                              placeholder="Mobile"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Email Address</label>
                            <input
							onChange={this.handleForm}
								name="email"
								value={this.state.email}
                              type="email"
                              className="form-control"
                              placeholder="Email Address"
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <label>Price Range</label>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">

                      <select onChange={this.handleForm} className="form-control" value={this.state.priceMinMax} name="priceMinMax" id="">
                        <option value="$300k to $500k">$300k to $500k</option>
                        <option value="$500k to $1M">$500k to $1M</option>
                        <option value="Over $1M">Over $1M</option>
                      </select>


                            
                          </div>
                        </div>
                       
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>County Preferences </label>							
                            <div className="customchecktnwrap multiselectfull">
                            <span
        class="d-block full-w"
        data-toggle="popover"
        data-trigger="focus"
        data-content="Please select account(s)"
      >
       <MySelect
          options={this.state.state}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option,
            MultiValue,
            ValueContainer,
            animatedComponents
          }}
          onChange={this.handleChangeState}
          allowSelectAll={true}
          value={this.state.optionSelectedState}
        />
      </span>
                            </div>
                          </div>
                        </div>
						{this.state.optionSelectedState && this.state.optionSelectedState.map((item, index) => 
						<div className="col-sm-6">
						<div className="form-group">
                            <label>City {this.state.optionSelectedState[index].value} </label>
                            <div className="customchecktnwrap multiselectfull">
						
					        <span
        class="d-block full-w"
        data-toggle="popover"
        data-trigger="focus"
        data-content="Please select account(s)"
      >
		 
       <MySelect
          options={this.state.city[this.state.optionSelectedState[index].value]}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option,
            MultiValue,
            ValueContainer,
            animatedComponents
          }}
          onChange={(e)=> this.handleChangeCity(e, index )}
          allowSelectAll={true}
          value={this.state['cityFilter'+index]}
        />
      </span>
	  
                            </div>
                          </div>


						</div>
)}
                      
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>Additional Buyer Notes</label>
                            <textarea
                            value={this.state.note}
                              onChange={this.handleForm}
                              name="note"
                              rows="4"
                              className="form-control no-resize"
                              placeholder=" Description"
                            >{this.state.note}</textarea>
                          </div>
                        </div>

                       

                        <div className="col-sm-12 formbtnsec">
                          <button
                            type="submit"
                            className="btn btn-primary btnfil"
                          >
                            SAVE
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default withRouter(Buyerprofile);
