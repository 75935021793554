import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/layout";
import Bredcrumb from "../../components/layout/bredcrumb";
import { Table } from "react-bootstrap";
import { Route, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Watch } from "react-loader-spinner";
import AuthUser from "../../components/AuthUser";

import Iframe from "react-iframe";

const Hotleadsdetail = () => {
	let { id } = useParams();
	const [loader, setLoader] = useState(false);
	const { http, setToken, token, user } = AuthUser();

	const [proDetail, setProDetail] = useState(null);
	const [address, setAddress] =useState("");
	const [allImages, setAllImages] = useState([])


	useEffect(() => {
		setLoader(true);
		const fetchData = async () => {
		  const res = await http.get("/get_lead_detail/"+id);
	
		  let ar = [];
		  let arr = [];
		  let datacus = res.data.data;
			
		  setAllImages(JSON.parse(datacus.all_images))
	
		  setAddress(`${datacus.address}, ${datacus.city}, ${datacus.state}`)
			console.log(datacus);
		  setProDetail(datacus)
		
		  let buyer = [];
		  let selectBuySet = [];
		  for (let j = 0; j < res.data.buyers.length; j++) {
			buyer.push({ value:res.data.buyers[j].id, label: res.data.buyers[j].first_name+' '+res.data.buyers[j].last_name })
			
			if( datacus.agents.includes( res.data.buyers[j].id )){
			  selectBuySet.push({value: res.data.buyers[j].id, label:res.data.buyers[j].first_name+' '+res.data.buyers[j].last_name})
			 }
		  }  
		  //   console.log();
		  setLoader(false);
		};
		fetchData().catch(console.error);
	  }, []);
	

  return (
    <Layout>
      <div className="container-fluid p-0">
        <section className="contentinnerpage listingpage">
          <Bredcrumb title="Hot Leads Detail" desc="Welcome to Offrz" />

          <div className="pagedeail-content">
            <div className="row clearfix">
              <div className="col-lg-8 col-md-12">
                <div className="card">
                  <div className="body">
                    <div
                      id="carouselExampleCaptions"
                      className="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-indicators">
                        <button
                          type="button"
                          data-bs-target="#carouselExampleCaptions"
                          data-bs-slide-to="0"
                          className="active"
                          aria-current="true"
                          aria-label="Slide 1"
                        ></button>
                        <button
                          type="button"
                          data-bs-target="#carouselExampleCaptions"
                          data-bs-slide-to="1"
                          aria-label="Slide 2"
                        ></button>
                        <button
                          type="button"
                          data-bs-target="#carouselExampleCaptions"
                          data-bs-slide-to="2"
                          aria-label="Slide 3"
                        ></button>
                      </div>
                      <div className="carousel-inner">

							{allImages && allImages.map((item, ind) => (
								<div className={ind === 0 ? "carousel-item  active":"carousel-item " }>
								<img
								  src={"http://portal.offrz.net/"+item}
								  className="d-block w-100"
								  alt="..."
								/>
							   
							  </div>
							))}

                        
                       
                      
                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>

                    <h6 className="text-success mt-3">$390,000 - $430,000</h6>
                    <h5 className="mt-0">
                      <a href="#" className="col-blue-grey">
                        {address}
                      </a>
                    </h5>
                    <p className="text-muted">
                    {proDetail && proDetail.description}
                    </p>
                    <ul className="hotlistdetail">
                      <li>
                        <strong>Full Name</strong>
                        <span>{proDetail && proDetail.first_name} {proDetail && proDetail.last_name}</span>
                      </li>
                      <li>
                        <strong>Email</strong>
                        <span>psmith@gmail.com</span>
                      </li>
                      <li>
                        <strong>Mobile</strong>
                        <span>(760) 367-1716</span>
                      </li>
                      <li>
                        <strong>City</strong>
                        <span>{proDetail && proDetail.city}</span>
                      </li>
                      <li>
                        <strong>State</strong>
                        <span>{proDetail && proDetail.state}</span>
                      </li>
                      <li>
                        <strong>Postal Code</strong>
                        <span>{proDetail && proDetail.zip_code}</span>
                      </li>
                      <li>
                        <strong>Address</strong>
                        <span>{address}</span>
                      </li>
                    
                    </ul>
                  </div>
                </div>
                <div className="card">
                  <div className="header">
                    <h2>
                      <strong>General</strong> Amenities
                      <small>Description Text Here...</small>
                    </h2>
                  </div>
                  <div className="body">
                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <ul className="row">
				
							{proDetail && proDetail.data.map((item, i) => 
                          <li className="col-md-6 justify-content-between d-flex list-group-item">
                           <span className="titlepro">  <i className="me-2 fas fa-fw fa-check-circle"></i>
                           {item.name } </span> <span className="descpro"> {item.value || "-"}</span>
                          </li>)} 
                         
                        </ul>
                      </div>
                    
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="header">
                    <h2>
                      <strong>Location</strong>{" "}
                      <small>Description text here...</small>{" "}
                    </h2>
                  </div>
                  <div className="body">
				  <div className="mapsection">
                          <Iframe
                            src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyA28VgphVkwvtSU0fxgsysah-gKBQZi8L4&q="+address}
                            width="100%"
                            height="450"
                            style="border:0;"
                            allowfullscreen=""
                            loading="lazy"
                          ></Iframe>
						  </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
               

                <div className="card">
                  <div className="header">
                    <h2>
                      <strong>Location</strong>
                    </h2>
                  </div>
                  <div className="body">
                    <div className="table-responsive">
                      <Table className="table table-bordered m-b-0">
                        <tbody>
                          <tr>
                            <th scope="row">Min Price:</th>
                            <td>${proDetail && proDetail.min_price}</td>
                          </tr>
                          <tr>
                            <th scope="row">Max Price:</th>
                            <td>${proDetail && proDetail.max_price}</td>
                          </tr>
                          <tr>
                            <th scope="row">Contract type: </th>
                            <td>
                              <span className="badge rounded-pill bg-secondary">
                                For Sale
                              </span>
                            </td>
                          </tr>
                        
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="header">
                    <h2>
                      <strong>Request</strong> Inquiry
                    </h2>
                  </div>
                  <div className="body customform">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Mobile No."
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        rows="4"
                        className="form-control no-resize"
                        placeholder="Please type what you want..."
                      ></textarea>
                    </div>
                    <div className="col-sm-12 formbtnsec">
                      <button type="submit" className="btn btn-primary btnfil">
                        Submit
                      </button>
                      <button
                        type="submit"
                        className="btn btn-default btn-stroke"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Hotleadsdetail;
