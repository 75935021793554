import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import Bredcrumb from "../components/layout/bredcrumb";
import Layout from "../components/layout/layout";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import differenceBy from "lodash/differenceBy";
const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    /> 
  </>
);

const columns = [
  {
    name: "#",
    selector: (row) => row.id,
  },
  {
    name: "First Name",
    selector: (row) => row.owner_names,
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
  },
  {
    name: "Mobile No",
    selector: (row) => row.mobile_no,
    sortable: true,
  },  
  {
    name: "Address",
    selector: (row) => row.address,
    sortable: true,
  },
  {
    name: "City",
    selector: (row) => row.city,
    sortable: true,
  },
  {
    name: "State",
    selector: (row) => row.state,
    sortable: true,
  },

];
function Csvprint() {
  const [state, setstate] = useState([]);
  const [show, setShow] = useState(false);
  const [inputs, setInputs] = useState([]);
  const [groups, setgroups] = useState([]);
  const [deleteData, setDeleteData] = useState("");
  const [pending, setpending] = useState(false);
  const handleClose = () => setShow(false);
  const [formData, setFormData] = useState([]);

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [editable, setEditable] = useState("");

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = state.filter(
    (item) =>
      item.owner_names &&
      item.owner_names.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = React.useMemo(() => {
    const handleDelete = () => {
      if (
        window.confirm(
          `Are you sure you want to delete:\r ${selectedRows.map(
            (r) => r.title
          )}?`
        )
      ) {
        setToggleCleared(!toggleCleared);
        // setState(differenceBy(filteredItems, selectedRows, "ownder"));
      }
    };

    return (
      <Button
        key="delete"
        onClick={handleDelete}
        style={{ backgroundColor: "red" }}
        icon
      >
        Delete
      </Button>
    );
  }, [state, selectedRows, toggleCleared]);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div>
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const handleShow = (id) => {
    setShow(true);

    axios
      .get("http://portal.offrz.net/api/getuserdetail/" + id, {
        headers: {
          token: "mt1",
          auth: "z1OcPfxhX0o3cup08SYGeUwZWcoAtyMJY1p0xjktHEZXNLJRSIBw5hd2evC3RdMA9l6aZwT4cRCYaYx0",
        },
      })
      .then(function (response) {
        // handle success

        setInputs(JSON.parse(response.data.data.address.data));
        console.log(response.data.data.users_group);
        setgroups(response.data.data.users_group);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    //
    http: console.log("working fine" + id);
  };

  useEffect(() => {
    setpending(true);
    axios
      .get("http://portal.offrz.net/api/alladdressdata", {
        headers: {
          token: "mt1",
          auth: "z1OcPfxhX0o3cup08SYGeUwZWcoAtyMJY1p0xjktHEZXNLJRSIBw5hd2evC3RdMA9l6aZwT4cRCYaYx0",
        },
      })
      .then(function (response) {
        // handle success
        setpending(false);
        setstate(response.data.data.data);
        console.log(response.data.data.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, [deleteData]);

  const removeAddress = (id) => {
    axios
      .delete("http://portal.offrz.net/api/deleteaddress/" + id, {
        headers: {
          token: "mt1",
          auth: "z1OcPfxhX0o3cup08SYGeUwZWcoAtyMJY1p0xjktHEZXNLJRSIBw5hd2evC3RdMA9l6aZwT4cRCYaYx0",
        },
      })
      .then(function (response) {
        // handle success
        setDeleteData(id);
        toast.success("successfully removed");
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  const handleChange = (e) => {
    let el = e.target;
    setFormData([
      ...formData,
      { name: el.placeholder, data_parm: el.name, value: el.value },
    ]);
  };

  return (
    <Layout>
      <div className="container-fluid p-0">
        <section className="contentinnerpage listingpage">
          <Bredcrumb title="Property List" desc="Welcome to Offrz" />

          <div className="row clearfix">
            <div className="col-12">
              <div className="card">
                <div className="card-body">                  
                <div className="bbtndeledit">
                   {editable.selectedCount == 1 ? (
                    <div className="bbtndeleditinner">
                        <Button className="editbtn"
                          onClick={() => {
                            handleShow(editable.selectedRows[0].id);
                            console.log(editable.selectedRows);
                          }}
                        >
                            <i className="fas fa-pencil-alt"></i> Edit
                        </Button>
                        <Button  className="delbtn"
                          onClick={() =>
                            removeAddress(editable.selectedRows[0].id)
                          }
                        >
                         <i className="fas fa-trash-alt"></i>  Delete
                        </Button>
                        </div>
                     
                    ) : (
                      editable.selectedCount && <Button  className="delbtn" >Delete</Button>
                    )}
                    </div>
                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    progressPending={pending}
                    selectableRows
                    pagination
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    persistTableHead
                    contextActions={contextActions}
                    onSelectedRowsChange={(item) => setEditable(item)}
                    clearSelectedRows={toggleCleared}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal className="editpopup" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {inputs.map((item, index) => {
            return (
              <div>
                <div className="form-group">
                  <label>{item.name}</label>
                  <input
                    placeholder={item.data_parm}
                    onChange={(e) => handleChange(e)}
                    name={item.data_parm}
                    type={item.type}
                    value={item.value}
                    className="form-control"
                  />
                </div>
              </div>
            );
          })}
            <div className="filedgroup-wrap">
          {groups.map((item, index) => {
            return (
              <div className="form-group">
                <div className="customchecktnwrap">
															<div className="form-check">
                              <input type="checkbox" className="form-check-input" />
																<label className="form-check-label" for="">{item.name}</label>
															</div>
														 
														</div> 
              </div>
            );
          })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => console.log(formData)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}

export default Csvprint;
