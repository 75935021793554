import React from 'react'

export default function signup() {
  return (
    <div className="loginpage">
    <div className="card-plain">
<form className="form" method="" action="">
<div className="header">
<div className="logo-container">
<img src="img/logo.png" alt="logo" />
</div>

</div>
<div className="content">
<div className="input-group input-lg">
<input type="text" className="form-control" placeholder="Enter User Name" />
<span className="input-group-addon">
<i className="fas fa-user-circle"></i>
</span>
</div>
<div className="input-group input-lg">
<input type="text" className="form-control" placeholder="Enter Email" />
<span className="input-group-addon">
<i className="far fa-envelope"></i>
</span>
</div>
<div className="input-group input-lg">
<input type="password" placeholder="Password" className="form-control" />
<span className="input-group-addon">
<i className="fas fa-lock"></i>
</span>
</div>
<div className="privacypoilcy">
<div className="checkbox">
<input id="terms" type="checkbox" />
<label for="terms">
I read and agree to the <a href="#">terms of usage</a>
</label>
</div>
</div>
</div>
<div className="footer text-center">
<input type="submit" className="sendbtn" value="SIgn Up" /> 
</div>
</form>
</div>
</div>
  )
}
