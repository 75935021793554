import React, { useState, useEffect } from "react";
import {

  
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import Footer from "./footer";
import Navbar from "./navbar";
import Sidebar from "./sidebar";
import AuthUser from "../AuthUser";


export default function Layout({ children }) {
  const [open, setOpen] = useState(false);
 
  let history = useHistory();


  const {token,logout} = AuthUser();
  const logoutUser = () => {
      if(token != undefined){
          logout();
      }
  }
 
  
  useEffect(() => {
    if(token == undefined || token == null){
      history.push('/login');
     
  }
  },[])
  

  
  const toggleHandle = () => {
    console.log("hello worl");
    setOpen((prevCheck) => !prevCheck);
  };




  return (
    <div className="wrapper">
      {open}
      <Sidebar open={open} />
      <div className="main">
        <Navbar toggleHandle={toggleHandle} />

        <main className="content">{children}</main>
        <Footer />
      </div>
    </div>
  );
}
