import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Menu, Collapse } from "react-bootstrap";

import AuthUser from "../AuthUser";

export default function Navbar({ toggleHandle }) {
  const {token,logout, user} = AuthUser();

  return (
    <nav className="navbar navbar-expand navbar-light navbar-bg">
      <div className="logobrand">
        <Link to="/">
          <img src="/img/logo.png" alt="Stacie Hall" />
        </Link>
      </div>
      <a onClick={() => toggleHandle()} className="sidebar-toggle">
        <i className="fas fa-fw fa-exchange-alt"></i>
      </a>
      <Dropdown className="navbar-collapse collapse mainheadnav">
        <ul className="navbar-nav navbar-align">
          <li className="nav-item dropdown">
            <Dropdown.Toggle
              to="/"
              className="nav-icon dropdown-toggle"
              href="#"
              id="messagesDropdown"
              data-bs-toggle="dropdown"
            >
              <div className="position-relative">
              <i class="align-middle me-2 fas fa-fw fa-envelope"></i>
                <div className="notify">
                  <span className="heartbit"></span>
                  <span className="point"></span>
                </div>
              </div>
            </Dropdown.Toggle>
            {user.role ==="admin" && 
            <Dropdown.Menu
              className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0"
              aria-labelledby="messagesDropdown"
            >
              <div className="dropdown-menu-header">
                <div className="position-relative">4 New Messages</div>
              </div>
              <div className="list-group">
                <Link to="/" href="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                      <img
                        src="/img/avatars/avatar-5.jpg"
                        className="avatar img-fluid rounded-circle"
                        alt="Ashley Briggs"
                      />
                    </div>
                    <div className="col-10 ps-2">
                      <div className="text-dark">Ashley Briggs</div>
                      <div className="text-muted small mt-1">
                        Nam pretium turpis et arcu. Duis arcu tortor.
                      </div>
                      <div className="text-muted small mt-1">15m ago</div>
                    </div>
                  </div>
                </Link>
                <Link to="/" href="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                      <img
                        src="/img/avatars/avatar-2.jpg"
                        className="avatar img-fluid rounded-circle"
                        alt="Carl Jenkins"
                      />
                    </div>
                    <div className="col-10 ps-2">
                      <div className="text-dark">Carl Jenkins</div>
                      <div className="text-muted small mt-1">
                        Curabitur ligula sapien euismod vitae.
                      </div>
                      <div className="text-muted small mt-1">2h ago</div>
                    </div>
                  </div>
                </Link>
                <Link to="/" href="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                      <img
                        src="/img/avatars/avatar-4.jpg"
                        className="avatar img-fluid rounded-circle"
                        alt="Stacie Hall"
                      />
                    </div>
                    <div className="col-10 ps-2">
                      <div className="text-dark">Stacie Hall</div>
                      <div className="text-muted small mt-1">
                        Pellentesque auctor neque nec urna.
                      </div>
                      <div className="text-muted small mt-1">4h ago</div>
                    </div>
                  </div>
                </Link>
                <Link to="/" href="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                      <img
                        src="/img/avatars/avatar-3.jpg"
                        className="avatar img-fluid rounded-circle"
                        alt="Bertha Martin"
                      />
                    </div>
                    <div className="col-10 ps-2">
                      <div className="text-dark">Bertha Martin</div>
                      <div className="text-muted small mt-1">
                        Aenean tellus metus, bibendum sed, posuere ac, mattis
                        non.
                      </div>
                      <div className="text-muted small mt-1">5h ago</div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="dropdown-menu-footer">
                <Link to="/" href="#" className="text-muted">
                  Show all messages
                </Link>
              </div>
            </Dropdown.Menu>
            }
          </li>
          <li className="nav-item dropdown">
            <Link
              to="/"
              className="nav-icon dropdown-toggle"
              href="#"
              id="alertsDropdown"
              data-bs-toggle="dropdown"
            >
              <div className="position-relative">
              <i class="align-middle me-2 fas fa-fw fa-bell"></i>
              </div>
            </Link>
            <div
              className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0"
              aria-labelledby="alertsDropdown"
            >
              <div className="dropdown-menu-header">4 New Notifications</div>
              <div className="list-group">
                <a target="_blank" href={user.signature_url} className="list-group-item">
                  <div className="row g-0 align-items-center">
                    
                    <div className="col-12">
                      <div className="text-dark">  Contract sign with OFFRZ </div>
                      <div className="text-muted small mt-1">
                       Open link
                      </div>
                      <div className="text-muted small mt-1">2h ago</div>
                    </div>
                  </div>
                </a>
              
             
               
              </div>
              <div className="dropdown-menu-footer">
                <Link to="/" href="#" className="text-muted">
                  Show all notifications
                </Link>
              </div>
            </div>
          </li>
          {user.role ==="admin" && 
          <li className="nav-item dropdown">
            <Link
              to="/"
              className="nav-flag dropdown-toggle"
              href="#"
              id="languageDropdown"
              data-bs-toggle="dropdown"
            >
              <img src="/img/flags/us.png" alt="English" />
            </Link>
            <div
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="languageDropdown"
            >
              <Link to="/" className="dropdown-item" href="#">
                <img
                  src="/img/flags/us.png"
                  alt="English"
                  width="20"
                  className="align-middle me-1"
                />
                <span className="align-middle">English</span>
              </Link>
              <Link to="/" className="dropdown-item" href="#">
                <img
                  src="/img/flags/es.png"
                  alt="Spanish"
                  width="20"
                  className="align-middle me-1"
                />
                <span className="align-middle">Spanish</span>
              </Link>
              <Link to="/" className="dropdown-item" href="#">
                <img
                  src="/img/flags/de.png"
                  alt="German"
                  width="20"
                  className="align-middle me-1"
                />
                <span className="align-middle">German</span>
              </Link>
              <Link to="/" className="dropdown-item" href="#">
                <img
                  src="/img/flags/nl.png"
                  alt="Dutch"
                  width="20"
                  className="align-middle me-1"
                />
                <span className="align-middle">Dutch</span>
              </Link>
            </div>
          </li>
}
        </ul>
      </Dropdown>
      {user.role ==="admin" && 
      <form className="d-none d-sm-inline-block">
        <div className="input-group input-group-navbar">
          <input
            type="text"
            className="form-control"
            placeholder="Search…"
            aria-label="Search"
          />
          <button className="btn" type="button">
          <i class="align-middle me-2 fas fa-fw fa-search"></i>
          </button>
        </div>
      </form>
}
      <div className="navbar-collapse collapse usermenus">
        <ul className="navbar-nav navbar-align">
          <li className="nav-item">
            <a
                onClick={() => logout()}  
              className="nav-link   d-none d-sm-inline-block"
              href="#"
              data-bs-toggle=""
            >
              <i className="fas fa-fw fa-power-off"></i>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
