import React, { Component } from 'react'
import Layout from './../components/layout/layout'
import Bredcrumb from "../components/layout/bredcrumb"; 
import { Table } from 'react-bootstrap'; 
import { Tabs } from 'react-bootstrap'; 
import { Link } from "react-router-dom";

export class Detailproperty extends Component {
  render() {
      
    return (
    <Layout>
       <div className="container-fluid p-0">
        <section className="contentinnerpage buyer-detail listingpage">
          <Bredcrumb title="Propert Detail" desc="Welcome to Offrz" />

          <div className="pagedeail-content">
          <div className="propertydetail">
          <div className="card">
										<div className="body">
	<div className="row">
		<div className="col-md-8">
			<div className="singleamin">
			<div className="slidersection">
            <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
												<div className="carousel-indicators">
												  <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
												  <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
												  <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
												</div>
												<div className="carousel-inner">
												  <div className="carousel-item active">
													<img src="img/gal1.jpg" className="d-block w-100" alt="..." />
												 
												  </div>
												  <div className="carousel-item">
													<img src="img/gal2.jpg" className="d-block w-100" alt="..." />
												 
												  </div>
												  <div className="carousel-item">
													<img src="img/gal3.jpg" className="d-block w-100" alt="..." />
												 
												  </div>
												</div>
												<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
												  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
												  <span className="visually-hidden">Previous</span>
												</button>
												<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
												  <span className="carousel-control-next-icon" aria-hidden="true"></span>
												  <span className="visually-hidden">Next</span>
												</button>
											  </div>


            </div>
			<div className="videomapsec">
				<div className="videosec"></div>
				<div className="location"></div>
			</div>
		</div>

<div className="fulldescription">
	<div className="tabsection">

    <div className="sidebar-content buyerdescwrap js-simplebar">
        <ul className="nav nav-tabs buyerdesctabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="overview-tab"
              data-bs-toggle="tab"
              data-bs-target="#overview"
              type="button"
              role="tab"
              aria-controls="overview"
              aria-selected="true">
                Overview
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="offerfaq-tab"
              data-bs-toggle="tab"
              data-bs-target="#offerfaq"
              type="button"
              role="tab"
              aria-controls="offerfaq"
              aria-selected="false"
            >   Offer FAQ
            </button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="overview"
            role="tabpanel"
            aria-labelledby="menu-tab"
          >
           <p>4bed/2bath single family home in Long Beach. Home needs updating throughout. This house is on a private road. The few homes on that private road pay $100 monthly HOA.</p>
           <div className="autorinfo">
 	<div className="authorimg">	<div className="p-pic">
											 <img src="img/avatars/avatar-2.jpg" className="rounded-circle " alt="profile-image" />  
										</div></div>
 	<div className="autohrodetail">
 		<h5>Questions? Connect with a Sundae Investor Advisor.</h5>
 		<p>Victoria Rios<br/>
CA#: 02155965 <br/>
info@yourmail.com<br/>
Phone(310) 928-9746</p>
 	</div>
 </div>
     
          </div>
          <div
            className="tab-pane fade"
            id="offerfaq"
            role="tabpanel"
            aria-labelledby="offerfaq-tab"
          >
            <div className="faqsectionbuyer">
	<h2>Understanding the Fees and Terms</h2>
	<div className="row">
		<div className="col-md-8"><div className="faqs">
			<h3>Investor Responsibility</h3>
			<p>Investors are responsible for transaction fees only when an offer is accepted. If you win you must sign a purchase sale agreement, which may not be assigned by either party and there are no contingencies.</p>
			<h3>Buyer Premium</h3>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id semper eros, id blandit tortor. Proin euismod dolor at dolor ornare venenatis. Vivamus pellentesque tincidunt gravida. Aliquam congue lorem ante, porta commodo urna aliquam in. Sed interdum convallis mauris, sed vulputate ex ornare vitae. </p>
			<h3>Transaction Fees</h3>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id semper eros, id blandit tortor. Proin euismod dolor at dolor ornare venenatis. Vivamus pellentesque tincidunt gravida. Aliquam congue lorem ante, porta commodo urna aliquam in. Sed interdum convallis mauris, sed vulputate ex ornare vitae. </p>
			<h3>Closing Costs</h3>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id semper eros, id blandit tortor. Proin euismod dolor at dolor ornare venenatis. Vivamus pellentesque tincidunt gravida. Aliquam congue lorem ante, porta commodo urna aliquam in. Sed interdum convallis mauris, sed vulputate ex ornare vitae. </p>
			<h3>EMD</h3>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id semper eros, id blandit tortor. Proin euismod dolor at dolor ornare venenatis. Vivamus pellentesque tincidunt gravida. Aliquam congue lorem ante, porta commodo urna aliquam in. Sed interdum convallis mauris, sed vulputate ex ornare vitae. </p>
		</div></div>
		<div className="col-md-4"><div className="examplebox">
			<h5>Example</h5>
			<ul className="examplelist">
				<li> <span className="egtitle">Net to Seller</span>  <span>$186,915</span></li>
				<li> <span className="egtitle"> Buyer Premium (7%) </span> <span>$14,000</span></li>
				<li className="total"> <strong>Buyer Submitted Offer</strong> <strong>$200,000</strong></li>
				<li> <span className="egtitle">Est Closing Costs (1.5%)</span> <span>+ $3,000</span></li>
				<li> <span className="egtitle">Admin Fee</span> <span>+ $1,000</span></li>
				<li className="borders"> </li>
				<li className="total"> <strong>Total Est Cost</strong> <strong>$204,000</strong></li>
			</ul>
		</div></div>
	</div>
</div>
<div className="winnigtipssec">
	<h2>Tips to Winning</h2>
	<div className="tipsblock">
		<div className="tipicon"><i className="fas fa-check-circle"></i></div>
		<div className="tipcaption"><h3>Submit offers early</h3>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id semper eros, id blandit tortor. Proin euismod dolor at dolor ornare venenatis. Vivamus pellentesque tincidunt gravida. </p></div>
	</div>
	<div className="tipsblock">
		<div className="tipicon"><i className="fas fa-check-circle"></i></div>
		<div className="tipcaption"><h3>Place your highest and best</h3>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id semper eros, id blandit tortor. Proin euismod dolor at dolor ornare venenatis. Vivamus pellentesque tincidunt gravida. </p></div>
	</div>
	<div className="tipsblock">
		<div className="tipicon"><i className="fas fa-check-circle"></i></div>
		<div className="tipcaption"><h3>Prepare your capital</h3>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id semper eros, id blandit tortor. Proin euismod dolor at dolor ornare venenatis. Vivamus pellentesque tincidunt gravida. </p></div>
	</div>
	<div className="tipsblock">
		<div className="tipicon"><i className="fas fa-check-circle"></i></div>
		<div className="tipcaption"><h3>Stay active</h3>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id semper eros, id blandit tortor. Proin euismod dolor at dolor ornare venenatis. Vivamus pellentesque tincidunt gravida. </p></div>
	</div>
	<div className="tipsblock">
		<div className="tipicon"><i className="fas fa-check-circle"></i></div>
		<div className="tipcaption"><h3>Get connected</h3>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id semper eros, id blandit tortor. Proin euismod dolor at dolor ornare venenatis. Vivamus pellentesque tincidunt gravida. </p></div>
	</div>
</div>
          </div>
        </div>
      </div>



                                          
	</div>
</div>

	</div>
		<div className="col-md-4"><div className="signleside">
			<div className="timersection">
				<div className="offertiem"><p><i className="fas fa-clock"></i><span>OFFERS DUE
</span><strong>10:57</strong></p></div>
				<div className="noticetime"><span>DON’T WAIT! SOME SELLERS ACCEPT OFFERS EARLY.</span></div>
				<div className="infonot"><i className="fas fa-question-circle"></i></div>
			</div>
			<div className="statusection">
				<div className="statustext"><span className="badgeactive">ACTIVE</span><span className="badgeextend">EXTENDED</span></div>
				<div className="createdate"><span><i className="fas fa-calendar"></i> COE: 5/27/22</span></div>
			</div>
			<div className="prices"><h3>$527,500</h3></div>
			<div className="locatiosec">
				<div className="address-loc"><p>143 Corbin Way
Long Beach, CA 90805</p></div><div className="whislistsec"><i className="far fa-heart"></i>
<small>Save</small>
</div>
			</div>
			<div className="featurespro">
				<ul className="featurelist">
					<li>4 Bd</li>
					<li>2 Ba</li>
					<li>1,543 Sq Ft</li>
					<li>2004 Built</li>

				</ul>
			</div>
			<div className="enterpricesec">
				<div className="form-group"><input type="text" placeholder="Enter your offer amount" className="form-control" name="" /></div>
				<div className="form-group"><button className="btnprice">Place Offer and Review Terms </button></div>
			</div>

			<div className="inspectionblock">
				<div className="inspectionlist">
					<a href="#">Inspection Report <i className="fas fa-chevron-right"></i></a>
				</div>
				<div className="inspectionlist">
					<a href="#">Prelim Title Report <i className="fas fa-chevron-right"></i></a>
				</div>
				<div className="inspectionlist">
					<a href="#">Disclosures  <i className="fas fa-chevron-right"></i></a>
				</div>
			</div>
			<div className="heightlight">
				<h4>Highlights</h4>
				  <ul className="hotlistdetail">
                        <li><strong>Solar Panels</strong><span> No <i className="fas fa-times-circle"></i></span></li>
                        <li><strong>Septic System</strong><span>No <i className="fas fa-times-circle"></i></span></li>
                        <li><strong>In-Person Showings</strong><span>No <i className="fas fa-times-circle"></i></span></li>
                        <li><strong>HOA</strong><span>Yes <i className="fas fa-check-circle"></i></span></li>
                        <li><span>Delivered</span><strong>Vacant</strong></li>
                                                     
                      </ul>
			</div>
		</div></div>
	</div>
    </div>
</div>
</div>


							
						</div>



                </section>
      </div>
    </Layout>
    )
  }
}

export default Detailproperty